<template>
  <div>
    <div class="bg_center"></div>
    <headbox title="保单详情" bgColor="#39394d" :customPath="true" path="claimedit"  :query="{active:'b'}" isborder="true"></headbox>
    <div class="centerbox">
      <!-- 是否失效 -->
      <div class="white step_1 rel">
        <h1>{{ listdata.companyName }}</h1>
        <p>no.{{ listdata.expressNo }}</p>
        <p>
          <img
            src="@/assets/images/computericon.png"
            class="imgbox"
          />由中国太平洋保险有限公司承保
        </p>
        <div class="btn_see">
          <router-link :to="{ name: 'claimExplanation' }">理赔说明</router-link>
        </div>
        <div class="isovernow">
          <!-- "status":1,//保险状态  0 生效中/保障中  1 已失效/已完成  2 待生效 -->
          <img
            src="@/assets/images/overgame.png"
            alt=""
            v-if="listdata.status == 1"
          />
          <img
            src="@/assets/images/baozhangzhong.png"
            alt=""
            v-if="listdata.status == 0"
          />
          <img
            src="@/assets/images/daishengxiao.png"
            alt=""
            v-if="listdata.status == 2"
          />
        </div>
      </div>
      <!-- 保障内容 -->
      <div class="white step_2">
        <div class="h1">保障内容</div>
        <div class="flex-between">
          <p>被保险人</p>
          <p>{{ listdata.name }}</p>
        </div>
        <div class="flex-between">
          <p>保障期限</p>
          <p>{{ listdata.insuranceDuration }}</p>
        </div>
        <div class="flex-between">
          <p>投保额度</p>
          <p>{{ listdata.insuranceMoney }}元</p>
        </div>

        <div class="h1 mart">基本信息</div>
        <div class="flex-between">
          <p>投保人</p>
          <p>{{ listdata.insuranceBuyName }}</p>
        </div>
        <div class="flex-between">
          <p>投保时间</p>
          <p>{{ listdata.date }}</p>
        </div>
        <div class="flex-between">
          <p>顺丰单号</p>
          <p>{{ listdata.expressNo }}</p>
        </div>
        <div class="flex-between">
          <p>运输路线</p>
          <p>{{ listdata.insurancePath }}</p>
        </div>
        <div class="flex-between">
          <p>货品种类</p>
          <p>{{ listdata.goodName }}</p>
        </div>
        <div class="flex-between">
          <p>货品重量</p>
          <p>{{ listdata.weight }}g</p>
        </div>
        <div class="flex-between">
          <p>保险费</p>
          <p>{{ listdata.insuranceFee }}元</p>
        </div>
      </div>

      <div class="white seerule">
        查看<b
          ><router-link :to="{ name: 'InsuranceClause' ,query:{type:'claimDetails'} }"
            >《货运基本条例》</router-link
          ></b
        >
        <b
          ><router-link :to="{ name: 'claimeditHangkongxian',query:{type:'claimDetails'} }"
            >《附加航空险》</router-link
          ></b
        >和<b
          ><router-link :to="{ name: 'claimeditdelivery',query:{type:'claimDetails'} }"
            >《附加邮包险》</router-link
          ></b
        >
      </div>
    </div>
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
// 保险单详情
import * as InsuranceList from "@/utils/api";
export default {
  data() {
    return {
      listdata: [],
      // 订单号
      oederNo: ""
    };
  },
  created() {
    this.oederNo = this.$route.query.orderno;
  },
  mounted() {
    InsuranceList.queryInsuranceDetail(this.oederNo).then(res => {
      if (res.code == 0) {
        this.listdata = res.data;
      } else {
        this.$toast.fail(res.msg);
      }
    });
  },
  methods: {},
  components: {
    headbox
  }
};
</script>
<style lang="scss" scoped="true">
.imgbox {
  width: 120px;
  margin-right: 10px;
}
.isovernow {
  position: absolute;
  right: -40px;
  top: 20%;
  width: 25%;
}
.btn_see {
  width: 160px;
  height: 64px;
  text-align: center;
  line-height: 64px;
  color: #39394D;
  border: 2px solid #39394D;
  border-radius: 30px;
}
.mart {
  margin-top: 50px;
}
.step_1,
.step_2 {
  margin-bottom: 20px;
}
.white {
  background: #fff;
  padding: 0 30px;
}
.Application {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #ffb606;
  line-height: 90px;
  color: #fff;
  text-align: center;
  font-size: 34px;
}
.seerule {
  padding: 25px 30px;
  font-size: 24px;
  color: #333333;
  b {
    font-weight: normal;
    color: #39394D;
  }
}
.step_1 {
  background: #fff;
  padding: 30px;
  font-size: 28px;
  margin-bottom: 20px;
  h1 {
    font-size: 40px;
    color: #010000;
    margin-bottom: 15px;
  }
  p {
    color: #666666;
    margin-bottom: 20px;
    line-height: 42px;
  }
}
.step_2 {
  background: #fff;
  padding: 30px;
  font-size: 28px;
  margin-bottom: 20px;
  .flex-between {
    p:nth-child(1) {
      color: #1a1a1a;
    }
    p:nth-child(2) {
      color: #666666;
    }
  }
  .h1 {
    margin-bottom: 15px;
    font-size: 36px;
    color: #010000;
    font-weight: bold;
  }
  p {
    margin-bottom: 20px;
  }
}
.centerbox {
  overflow: hidden;
  position: relative;
  z-index: 3;
}
.bg_center {
  background: #f5f5f5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
</style>
